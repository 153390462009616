import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import inactivityBar from 'img/livesessionApp/inacivity-bar.png';

const Content = () => {
  return (
    <div>
      <p>
        A period of inactivity starts when 4 seconds have passed from the last interaction: a click,
        a mouse movement, a scroll or a tap (on mobile).
      </p>
      <Image src={inactivityBar} alt="Inactivity Bar" title="Bar" />
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-pause-recording/',
    title: 'How to pause recording?',
    description: 'Check how you can pause recording.',
  },
];

export const frontmatter = {
  metaTitle: 'How inactivity is calculated?',
  metaDescription: 'How we calculate inactivity on website',
  canonical: '/help/how-inactivity-is-calculated/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
